.App {
	text-align: left;
}

html {
	overflow-x: hidden;
	display: grid;
}

body {
	background-attachment: fixed;
	background-size: 100%;
}

/*links*/

li {
	display: inline;
}

a.link {
	color: black;
	padding: 5px;
	background-color: transparent;
}

.swal-content,
.swal-title {
	font-family: "Space Mono", monospace;
	padding-left: 2em;
	padding-right: 2em;
}
.swal-content {
	text-align: left;
}
.swal-button {
	padding: 7px 19px;
	border-radius: 2px;
	background-color: black;
	font-size: 12px;
	text-shadow: 0px -1px 0px rgba(255, 255, 255, 0.3);
	height: 32px;
	width: 55px;
}

.topnav .icon {
	display: none;
}
#skrollr-body {
	float: left;
	width: 100%;
	height: 100%;
}

/*storytelling components*/

#i4,
#i5,
#i6 {
	visibility: hidden;
}

#scrollIcons {
	position: fixed;
	right: 20px;
	margin-top: 5px;
}

#i4,
#i5,
#i6 {
	visibility: hidden;
}

#i1,
#i2,
#i4,
#i5,
#i6 {
	transform: rotate(90deg);
	margin-top: 10px;
	font-family: "Space Mono", monospace;
}
#i1 a,
#i2 a,
#i4 a,
#i5 a,
#i6 a {
	cursor: pointer;
}

#scrollIcons {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
}

/*fonts*/
/*
@font-face {
	font-family: "Akkurat-Mono";
	src: url(font.ttf) format("truetype");

	font-family: "Akkurat-Mono";
	src: url(font-mono.OTF) format("truetype");
}*/

li {
	font-family: "Space Mono", monospace;
	font-size: 1.5em;
	background-color: transparent;
}

svg.img_svg {
	width: 100vw;
	top: 0;
	margin-left: -20px;
	z-index: -1;
}

p {
	z-index: 2;
}

nav {
	z-index: 100;
}

svg.grid {
	height: 80vh;
	z-index: -1;
}

.logo svg {
	width: 100px;
	height: 100px;
	margin-top: 1em;
}

.childIntro {
	width: 100vw;
	height: 200vh;
	scroll-snap-align: start;
	position: relative;
}

.childM {
	width: 100vw;
	height: 223vh;
	scroll-snap-align: start;
	position: relative;
}

.childV {
	width: 100vw;
	height: 230vh;
	scroll-snap-align: start;
	position: relative;
}

.childC {
	width: 100vw;
	height: 255vh;
	scroll-snap-align: start;
	position: relative;
}
.childSobre,
.childPiloto {
	width: 100vw;
	height: 100vh;
	scroll-snap-align: start;
	position: relative;
}

.vantagem,
.inicio,
.mitos,
.condicoes {
	position: relative;
}

nav {
	position: fixed;
	margin-top: -1.6em;
	cursor: pointer;
}
.inicio p,
.vantagens p,
.mitos p,
.condicoes p {
	font-family: "Space Mono", monospace;

	position: fixed;
	top: 15em;
	padding-top: 50px;
	padding-bottom: 80px;

	margin: 0 auto;
	overflow: hidden;
	filter: drop-shadow(0em 0em 10px lightgray);
}

.sobre p:first-child,
.piloto p:first-child {
	top: 9em;
}

.sobre svg,
.piloto svg {
	height: 100vh;
}

.sobre p {
	font-family: "Space Mono", monospace;
	top: 9em;
	right: 18.6em;
	margin: 0 auto;
	overflow: hidden;
	font-size: 18px;
}
.piloto p {
	font-family: "Space Mono", monospace;
	top: 9em;
	right: 18.2em;
	margin: 0 auto;
	overflow: hidden;
	font-size: 18px;
}

.piloto img {
	height: 100%;
	margin-top: 15%;
	right: 18.2em;
}

.inicio p::after,
.vantagens p::after,
.mitos p::after,
.condicoes p::after {
	content: "";
	position: absolute;
	width: 1150%;
	bottom: 0;
	top: -1350px;
	right: -530%;
	background: rgba(255, 255, 255, 0.877);
	transform-origin: 54% 0;
	transform: rotate(45deg);
	z-index: -1;
}

.opcoesBtn {
	margin-left: 100px;
	margin-top: 40px;
}

button {
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;
	padding: 6px 24px;
	position: relative;
	border: none;
	height: 200px;
	width: 370px;
}

.buttonV {
	background: url("component/svgs/btn11.svg") no-repeat;
	background-size: 100%;
}

.buttonV:hover {
	background: url("component/svgs/btn1.svg") no-repeat;
	background-size: 100%;
}

.buttonM {
	background: url("component/svgs/btn12.svg") no-repeat;
	background-size: 100%;
}

.buttonM:hover {
	background: url("component/svgs/btn2.svg") no-repeat;
	background-size: 100%;
}
.buttonC {
	background: url("component/svgs/btn13.svg") no-repeat;
	background-size: 100%;
}

.buttonC:hover {
	background: url("component/svgs/btn3.svg") no-repeat;
	background-size: 100%;
}

.fullText {
	overflow-y: hidden;
}
.fullText::-webkit-scrollbar {
	display: none;
}

.progress-container {
	width: 100%;
	height: 4px;
}

.progress-bar {
	position: sticky;
	position: -webkit-sticky;
	height: 4px;
	background: #04aa6d;
	width: 0%;
}

#painel {
	opacity: 1;
	animation: opacidade 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}
#energia {
	opacity: 0.5;
	stroke-width: 5px;
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	animation: linha 3s, opacidade 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}
#local1,
#local2,
#local3,
#local4,
#local5,
#local6,
#estrago,
#inside1,
#inside2,
#outside1,
#outside2 {
	opacity: 0.5;
	stroke-width: 5px;
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	animation: linha 4s, opacidade 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}
#intro11 #energia,
#intro31 #linha {
	stroke-width: 3px;
}

#intro12 #energia,
#intro29 #energia,
#intro32 #energia,
#local1,
#local2,
#local3,
#local4,
#local5,
#local6 {
	stroke-width: 0.5px;
}

#scan {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	animation: linha 20s, opacidade 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	border-radius: 50%;
}

#linha {
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	animation: linha 3s, opacidade 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	border-radius: 50%;
}

#scan {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	animation: linha 5s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	border-radius: 50%;
}

#aguafrente {
	animation: agua 10s;
	animation-iteration-count: infinite;
	animation-direction: normal;
}

#aguafrente2 {
	animation: agua2 5s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#intro23 #frente,
#intro23 #janela,
#intro23 #cima,
#intro23 #lado {
	animation: salto 3s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#intro22 #saltoF,
#intro22 #saltoJ1,
#intro22 #saltoJ2,
#intro22 #saltoC,
#intro22 #saltoL {
	animation: salto2 3s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#intro11 #janela,
#intro12 #carro_j,
#v44 #janela {
	animation: opacidade 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#v44 #janela {
	animation: opacidade2 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#som4 {
	animation: shape 0.3s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#som2 {
	animation: shape2 0.2s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

#som3 {
	animation: shape3 0.5s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

.icon {
	width: 100px;
	height: 100px;
	margin-top: 3em;
}

@keyframes agua {
	from {
		height: 100;
		transform: translate(0, -10%);
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	100% {
		height: 0;
		transform: translate(0, 10.2%);
		opacity: 0;
	}
}

@keyframes agua2 {
	from {
		transform: translate(0, -1.4%);
	}
	100% {
		transform: translate(0, 1.4%);
	}
}

@keyframes salto {
	to {
		transform: translate(0, 7%);
		transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
	}
}

@keyframes salto2 {
	to {
		transform: translate(0, -4%);
		transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
	}
}

@keyframes linha {
	to {
		opacity: 1;
		stroke-dashoffset: 0;
		transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
	}
}

@keyframes shape {
	0% {
		transform: scale(0.97, 0.9);
	}
	100% {
		transform: scale(0.99, 1);
	}
}

@keyframes shape2 {
	100% {
		transform: scale(0.97, 1.02);
	}
	0% {
		transform: scale(1.002, 1.04);
	}
}

@keyframes shape3 {
	100% {
		transform: scale(1, 0.96);
		opacity: 0.7;
	}
	0% {
		transform: scale(1.002, 1.05);
		opacity: 1;
	}
}

@keyframes opacidade {
	0% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
	}
}

@keyframes opacidade2 {
	0% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.6;
	}
}

.wrap {
	height: 100%;
	width: 100%;
	text-align: center;
}

img {
	height: 20px;
	width: auto;
	margin-left: 50%;
	border: white;
}

.wrap img {
	margin: 0px 0 0 -40px;
	line-height: 60px;
	left: 250%;
	bottom: 0px;
}

.bounce {
	animation-iteration-count: infinite;
	animation-duration: 1.5s;
	animation: bounce 3.6s ease infinite;
	transform-origin: 50% 50%;
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	5.55556% {
		transform: translateY(0);
	}
	11.11111% {
		transform: translateY(0);
	}
	22.22222% {
		transform: translateY(-15px);
	}
	27.77778% {
		transform: translateY(0);
	}
	33.33333% {
		transform: translateY(-15px);
	}
	44.44444% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

/* Extra small devices (portrait phones, less than 576px)*/
@media (min-width: 325px) and (max-width: 425px) {
	.inicio p::after,
	.vantagens p::after,
	.mitos p::after,
	.condicoes p::after {
		width: 800%;
		top: -1020px;
	}
	svg.grid {
		height: 88vh;
		z-index: -1;
	}
	.text_edit {
		padding-left: 20px;
		padding-right: 5px;
		font-size: 10px;
	}

	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 30px;
		padding-bottom: 50px;
		margin-left: -20px;
	}
	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 15px;
		margin-top: 100px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 0.8em;
		margin-left: 60px;
	}

	.topnav li:not(:nth-child(2)) {
		display: none;
	}
	.topnav li.icon {
		float: right;
		display: block;
	}
	.topnav.responsive {
		position: relative;
		background-color: white;
	}
	.topnav.responsive .icon {
		position: absolute;
		width: 100px;
		height: 100px;
		margin-top: 3em;
		margin-left: 6.2em;
	}
	.topnav.responsive li {
		float: none;
		display: block;
		text-align: none;
		padding-bottom: 20px;
	}
	.buttonV {
		width: 150px;
	}

	.buttonV:hover {
		width: 150px;
	}

	.buttonM {
		width: 150px;
	}

	.buttonM:hover {
		width: 150px;
	}
	.buttonC {
		width: 150px;
	}

	.buttonC:hover {
		width: 150px;
	}

	button {
		display: block;
		padding: 6px 24px;
		position: relative;
		border: none;
		height: 50px;
		width: 370px;
		left: 35%;
	}

	.options {
		font-size: 10px;
		margin-left: -10%;
	}
	.sobre p {
		right: 4.6em;
		font-size: 12px;
		margin-top: 40px;
	}
	.piloto p {
		right: 4.6em;
		font-size: 12px;
		margin-top: 40px;
	}
}

/* Extra small devices (portrait phones, less than 576px)*/
@media (min-width: 425px) and (max-width: 575.98px) {
	.inicio p::after,
	.vantagens p::after,
	.mitos p::after,
	.condicoes p::after {
		width: 780%;
	}
	svg.grid {
		height: 88vh;
		z-index: -1;
	}
	.text_edit {
		padding-left: 20px;
		padding-right: 5px;
		font-size: 10px;
	}

	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 30px;
		padding-bottom: 50px;
		margin-left: -20px;
	}
	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 15px;
		margin-top: 100px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 0.8em;
		margin-left: 60px;
	}

	.topnav li:not(:nth-child(2)) {
		display: none;
	}
	.topnav li.icon {
		float: right;
		display: block;
	}
	.topnav.responsive {
		position: relative;
		background-color: white;
	}
	.topnav.responsive .icon {
		position: absolute;
		width: 100px;
		height: 100px;
		margin-top: 3em;
		margin-left: 6.5em;
	}
	.topnav.responsive li {
		float: none;
		display: block;
		text-align: none;
		padding-bottom: 20px;
	}
	.buttonV {
		width: 150px;
	}

	.buttonV:hover {
		width: 150px;
	}

	.buttonM {
		width: 150px;
	}

	.buttonM:hover {
		width: 150px;
	}
	.buttonC {
		width: 150px;
	}

	.buttonC:hover {
		width: 150px;
	}

	button {
		display: block;
		padding: 6px 24px;
		position: relative;
		border: none;
		height: 50px;
		width: 370px;
		left: 35%;
	}

	.options {
		font-size: 10px;
		margin-left: -10%;
	}
	.sobre p {
		right: 4.6em;
		font-size: 12px;
		margin-top: 40px;
	}
	.piloto p {
		right: 4.6em;
		font-size: 12px;
		margin-top: 40px;
	}
}

/* Small devices (landscape phones, less than 768px)*/
@media (min-width: 576px) and (max-width: 767.98px) {
	.inicio p::after,
	.vantagens p::after,
	.mitos p::after,
	.condicoes p::after {
		width: 960%;
	}
	.text_edit {
		padding-left: 20px;
		padding-right: 5px;
		font-size: 12px;
	}

	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 30px;
		padding-bottom: 50px;
		margin-left: -20px;
	}
	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 12px;
		margin-top: 85px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 1.2em;
		margin-left: 60px;
	}
	.topnav li:not(:nth-child(2)) {
		display: none;
	}
	.topnav li.icon {
		float: right;
		display: block;
	}
	.topnav.responsive {
		position: relative;
		background-color: white;
	}
	.topnav.responsive .icon {
		position: absolute;
		width: 100px;
		height: 100px;
		margin-top: 3em;
		margin-left: 6.3em;
	}
	.topnav.responsive li {
		float: none;
		display: block;
		text-align: none;
		padding-bottom: 20px;
	}
	.buttonV {
		width: 250px;
	}

	.buttonV:hover {
		width: 250px;
	}

	.buttonM {
		width: 250px;
	}

	.buttonM:hover {
		width: 250px;
	}
	.buttonC {
		width: 250px;
	}

	.buttonC:hover {
		width: 250px;
	}

	button {
		display: block;
		padding: 6px 24px;
		position: relative;
		border: none;
		height: 50px;
		width: 370px;
		left: 35%;
	}

	.options {
		font-size: 10px;
		margin-left: -5%;
	}
	.sobre p {
		right: 5.6em;
		font-size: 14px;
		margin-top: 20px;
	}
	.piloto p {
		right: 5.6em;
		font-size: 14px;
		margin-top: 20px;
	}
}

/* Medium devices (tablets, less than 992px)*/
@media (min-width: 768px) and (max-width: 991.98px) {
	.inicio p::after,
	.vantagens p::after,
	.mitos p::after,
	.condicoes p::after {
		width: 1050%;
	}
	.text_edit {
		padding-left: 25px;
		padding-right: 10px;
		font-size: 13px;
	}

	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 30px;
		padding-bottom: 50px;
		margin-left: -20px;
	}

	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 14px;
		margin-top: 85px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 1em;
		margin-left: 60px;
	}

	.topnav li:not(:nth-child(2)) {
		display: none;
	}
	.topnav li.icon {
		float: right;
		display: block;
	}
	.topnav.responsive {
		position: relative;
		background-color: white;
	}
	.topnav.responsive .icon {
		position: absolute;
		width: 100px;
		height: 100px;
		margin-top: 3em;
		margin-left: 6.5em;
	}
	.topnav.responsive li {
		float: none;
		display: block;
		text-align: none;
		padding-bottom: 20px;
	}

	.buttonV {
		width: 200px;
	}

	.buttonV:hover {
		width: 200px;
	}

	.buttonM {
		width: 200px;
	}

	.buttonM:hover {
		width: 200px;
	}
	.buttonC {
		width: 200px;
	}

	.buttonC:hover {
		width: 200px;
	}

	.options {
		font-size: 10px;
	}
	.sobre p {
		right: 6.6em;
		font-size: 16px;
	}
	.piloto p {
		right: 6.6em;
		font-size: 16px;
	}
}

/* Large devices (desktops, less than 1200px)*/
@media (min-width: 992px) and (max-width: 1259.98px) {
	.inicio p::after,
	.vantagens p::after,
	.mitos p::after,
	.condicoes p::after {
		width: 1120%;
	}

	.text_edit {
		padding-left: 80px;
		padding-right: 15px;
		font-size: 17px;
	}

	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 50px;
		padding-bottom: 80px;
	}
	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 17px;
		margin-top: 75px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 18px;
	}

	.buttonV {
		width: 250px;
	}

	.buttonV:hover {
		width: 250px;
	}

	.buttonM {
		width: 250px;
	}

	.buttonM:hover {
		width: 250px;
	}
	.buttonC {
		width: 250px;
	}

	.buttonC:hover {
		width: 250px;
	}

	.options {
		font-size: 10px;
	}

	.sobre p {
		right: 9.6em;
	}
	.piloto p {
		right: 9.6em;
	}
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1260px) and (max-width: 1440px) {
	.opcoesBtn {
		margin-left: 120px;
	}
	.options {
		margin-left: 50px;
	}
	.text_edit {
		padding-left: 80px;
		padding-right: 15px;
		font-size: 20px;
	}
	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 50px;
		padding-bottom: 80px;
	}

	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 17px;
		margin-top: 79px;
		padding-top: 5px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 17px;
	}

	.buttonV {
		width: 350px;
	}

	.buttonV:hover {
		width: 350px;
	}

	.buttonM {
		width: 350px;
	}

	.buttonM:hover {
		width: 350px;
	}
	.buttonC {
		width: 350px;
	}

	.buttonC:hover {
		width: 350px;
	}

	.options {
		font-size: 12px;
		background-color: transparent;
	}
}

@media (min-width: 1440px) {
	.inicio p::after,
	.vantagens p::after,
	.mitos p::after,
	.condicoes p::after {
		width: 1250%;
	}

	.opcoesBtn {
		margin-left: 120px;
	}
	.options {
		margin-left: 50px;
	}
	.text_edit {
		padding-left: 80px;
		padding-right: 15px;
		font-size: 20px;
	}
	.inicio p,
	.vantagens p,
	.mitos p,
	.condicoes p {
		top: 15em;
		padding-top: 50px;
		padding-bottom: 80px;
	}

	#i1,
	#i2,
	#i4,
	#i5,
	#i6 {
		font-size: 17px;
		margin-top: 79px;
		padding-top: 5px;
	}

	a.link {
		color: black;
		padding: 5px;
		background-color: transparent;
		font-size: 17px;
	}

	.buttonV {
		width: 350px;
	}

	.buttonV:hover {
		width: 350px;
	}

	.buttonM {
		width: 350px;
	}

	.buttonM:hover {
		width: 350px;
	}
	.buttonC {
		width: 350px;
	}

	.buttonC:hover {
		width: 350px;
	}

	.options {
		font-size: 12px;
		background-color: transparent;
	}
}
